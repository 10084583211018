export const baseFormConfig = {
  formItems: [
    {
      field: 'cardBin',
      type: 'input',
      label: 'card.card-bin',
      otherOptions: {
        disabled: true,
        maxlength: 20
      }
    },
    {
      field: 'bankName',
      type: 'input',
      label: 'card.bank-name',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    }
  ],
  validateRules: {
    bankName: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
  }
}
