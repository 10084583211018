<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      @handleNewClick="handleSubmitClick"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      :isResetFields="false"
    >
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/cardbin.edit.js'
import { useStore } from 'vuex'
import { ref, getCurrentInstance } from 'vue'
import i18n from '@/i18n'
import { useRouter, useRoute } from 'vue-router'
import _ from 'lodash'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const router = useRouter()
const routes = useRoute()

pageTitle.value = t('general.router-cardbin-edit')

const store = useStore()
const pageInfo = ref({})

const id = routes.params.id
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const cardBinInfo = await store.dispatch('param/getCardBinListByID', { id })
  cardBinInfo.tenantId = store.getters.tenantDataMap[cardBinInfo?.tenantId]
  pageInfo.value = cardBinInfo
}

const handleSubmitClick = async (data) => {
  const form = _.cloneDeep(data)
  form.id = id
  handleMessageCommit('param/handleEditCardBinSubmit', form).then(() => {
    router.push('/parameter/card-bin')
  })
}

getCurrentPageInfo()
</script>
